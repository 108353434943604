import React from "react"
import HeaderContent from "./header-content"


const Header = (_props) => {
  return (
    <header className="relative bg-white">
      <HeaderContent/>
    </header>
  )
}

Header.propTypes = {}

Header.defaultProps = {}

export default Header
