import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, container }) => {
  return (
    <div className="w-full bg-white">
      <Header/>
      <main className={`${container ? "container pb-20" : ""} mx-auto`}>{children}</main>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  container: PropTypes.bool,
}

export default Layout
