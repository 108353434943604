import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import RichText from "./richtext"

const BlogPost = ({ title, slug, content, titleIsLink, endFragment }) => {
  return (
    <article className="max-w-3xl mx-auto md:pb-24 md:pt-8 px-4 md:px-0">
      <div>
        {
          titleIsLink ?
            (
              <Link to={`/blog/${slug}`}>
                <h1 className="text-4xl font-semibold text-center hover:underline">{title}</h1>
              </Link>
            ) : (
              <h1 className="text-4xl font-semibold text-center">{title}</h1>
          )
        }
      </div>
      <div>
        <RichText content={content}/>
        {endFragment && <React.Fragment>{endFragment}</React.Fragment>}
      </div>
    </article>
  )
}

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired
}

export default BlogPost
