import React from "react"
import Layout from "../components/layout"
import BlogPost from "../components/blogpost"
import SEO from "../components/seo"

function BlogPostTemplate({ pageContext }) {
  const { blogPost } = pageContext

  return (
    <Layout>
      <SEO
        title={blogPost.title}
        description={blogPost.description}
      />
      <BlogPost
        content={blogPost.body.raw}
        slug={blogPost.slug}
        title={blogPost.title}
      />
    </Layout>
  )
}

export default BlogPostTemplate