import React from "react"
import PropTypes from "prop-types"


const nodeToReact = (node, attributes) => {
  const renderChildren = (node) =>
    node.children.map((node, idx) => nodeToReact(node, {key: idx}))

  switch (node.type) {
    case "paragraph":
      return (
        <p
          {...attributes}
          className="py-2"
        >
          {renderChildren(node)}
        </p>
      )
    case "link":
      return (
        <a
          href={node.href} {...attributes}
          className="text-black underline"
        >
          {renderChildren(node)}
        </a>
      )
    case "heading-one":
      return <h1 className="text-3xl font-semibold mt-2" {...attributes}>{renderChildren(node)}</h1>
    case "heading-two":
      return <h2 className="text-3xl mt-2" {...attributes}>{renderChildren(node)}</h2>
    case "heading-three":
      return <h3 className="text-2xl font-semibold mt-2" {...attributes}>{renderChildren(node)}</h3>
    case "heading-four":
      return <h4 className="text-2xl mt-2" {...attributes}>{renderChildren(node)}</h4>
    case "heading-five":
      return <h5 className="text-xl font-semibold mt-1" {...attributes}>{renderChildren(node)}</h5>
    case "heading-six":
      return <h6 className="text-xl mt-1" {...attributes}>{renderChildren(node)}</h6>
    case "image":
      return <img
          {...attributes}
          src={node.src}
          alt={node.altText}
          className="mx-auto my-4"
      />
    case "bulleted-list":
      return (
        <ul
          {...attributes}
          className="list-disc list-inside py-2"
        >
          {renderChildren(node)}
        </ul>
      )
    case "numbered-list":
      return (
        <ol
          {...attributes}
          className="list-decimal list-inside py-2"
        >
          {renderChildren(node)}
        </ol>
      )
    case "list-item":
      return (
        <li {...attributes}>{renderChildren(node)}</li>
      )
    case "list-item-child":
      return <React.Fragment {...attributes}>{renderChildren(node)}</React.Fragment>
    case "class":
      return <div {...attributes} className={node.className}>{renderChildren(node)}</div>
    default:
      if (node.type !== undefined)
        console.error(node);
      if (!!node.italic)
        return <em {...attributes}>{node.text}</em>
      if (!!node.bold)
        return <strong {...attributes}>{node.text}</strong>
      if (!!node.code)
        return <code {...attributes}>{node.text}</code>
      return <React.Fragment {...attributes}>{node.text} {node.text.endsWith("\n") && <br/>}</React.Fragment>
  }
}

const RichText = ({ content }) => {
  return (
    <div className="leading-normal text-base">
      {content.children.map((node, idx) => nodeToReact(node, {key: idx}))}
    </div>
  )
}

RichText.propTypes = {
  content: PropTypes.object
}

export default RichText